body,
.root {
    -webkit-font-smoothing: antialiased;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial,
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5;
    color: #333;
}

a {
    color: currentColor;
    text-decoration: none;
}

input,
textarea {
    outline: none;
}
